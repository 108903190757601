<template>
  <header class="header">
    <h1 class="glitched">CYBERINTELLIX</h1>
    <div class='glitch-window'>
      <h1 class="glitched">CYBERINTELLIX</h1>
    </div>
    <h2>PREVENIR ES LA MEJOR OPCIÓN</h2>
    <div class="start-button" @click="gotoServices">ENTRAR</div>
  </header>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  methods:{
    gotoServices(){
      this.$router.push("/stats")
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$background-color: #F9F8F8;
$headline-color: #bec923;

header{
  text-align: center;
}

header.header{
	position: absolute;
  top: 50%;
	left: 0;
	width: 100%;
	transform: translateY(-50%);
	h1{
		font-size: 2.5rem;
		color: $headline-color;
		text-align: center;
		margin-top: 0;
		text-transform: uppercase;
		font-weight: 900;
    text-align: center;
	}
  h2{
    margin: 15px auto;
    color:#F9F8F8;
    text-align: center;
    font-size:1.5rem;
    font-weight:300;
  }
}
.glitch-window{
	position: absolute;
	top: 0;
	left: -2px;
	width: 100%;
	color: $headline-color;
	text-shadow: 2px 0 $background-color, -1px 0 greenyellow, -2px 0 green;
	overflow: hidden;
	animation: crt-me 2500ms infinite linear alternate-reverse;
	// background: red;
}

.start-button{
  margin:25px auto;
  cursor: pointer;
	font-family: Helvetica, sans-serif;
	position: relative;
	display: inline-block;
	z-index: 100;
	transform: translate(-10%, 0%);
	color: white;
	text-decoration: none;
	padding: 0.75rem 1rem;
	border-radius: 80px;
	background: linear-gradient(270deg,#1e80dc,#c61590);
	//From Instagram...thanks
	transition: transform 250ms ease;
	&:hover, &:focus, &:active{
		transform: translate(-10%, -10%);
	}
}

//Animation Keyframes
@keyframes crt-me {
  0% {
      clip-path: inset(0% 0 50% 10%)
  }
  10% {
      clip-path: inset(0% 0% 50% 30%)
  }
  20% {
      clip-path: inset(0% 0% 50% 50%)
  }
  30% {
      clip-path: inset(0% 50% 90% 100%)
  }
  40% {
      clip-path: inset(50% 100% 80% 10%)
  }
  50% {
      clip-path: inset(0% 50 50% 30%)
  }
  60% {
      clip-path: inset(50% 0% 90% 50%)
  }
  70% {
      clip-path: inset(0% 50% 80% 100%)
  }
  80% {
      clip-path: inset(50% 0% 70% 10%)
  }
  90% {
      clip-path: inset(50% 0% 90% 30%)
  }
  100% {
      clip-path: inset(50% 0 50% 50%)
  }
}
@media screen and (min-width:760px) {
  header.header{
    h1{
      font-size:5rem;
    }
    h2{
      font-size:3rem;
    }
  }
}
</style>
